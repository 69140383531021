<template>
  <div>
    <div class="form-content">
      <h1 class="text-center">
        <img
          :src="require('../../assets/img/android-chrome-192x192.png').default"
          alt=""
          class="w-50 d-md-none"
        />
        <span class="brand-name" style="font-size: larger"
          >Password Reset was Successful</span
        >
      </h1>

      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130.2 130.2"
      >
        <circle
          class="path circle"
          fill="none"
          stroke="#73AF55"
          stroke-width="6"
          stroke-miterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <polyline
          class="path check"
          fill="none"
          stroke="#73AF55"
          stroke-width="6"
          stroke-linecap="round"
          stroke-miterlimit="10"
          points="100.2,40.2 51.5,88.8 29.8,67.5 "
        />
      </svg>

      <div class="text-center">
        <p class="mt-4 small">© 2021. Gerocare Solutions LTD.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SetPasswordSuccess",

  mounted() {
    setTimeout(() => {
      this.$router.push("/login");
    }, 2000);
  },

  methods: {},
};
</script>

<style scoped>
svg {
  width: 150px;
  display: block;
  margin: 40px auto 0;
}
.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.path.circle {
  -webkit-animation: dash 1.9s ease-in-out;
  animation: dash 1.9s ease-in-out;
}
.path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 1.9s 1.35s ease-in-out forwards;
  animation: dash 1.9s 1.35s ease-in-out forwards;
}
.path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 1.9s 1.35s ease-in-out forwards;
  animation: dash-check 1.9s 1.35s ease-in-out forwards;
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
</style>
